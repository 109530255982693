@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat'), url(./assets/fonts/Montserrat-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 300;
  src: local('Montserrat-Light'), url(./assets/fonts/Montserrat-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: local('Montserrat-Medium'), url(./assets/fonts/Montserrat-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
  src: local('Montserrat-Semibold'), url(./assets/fonts/Montserrat-SemiBold.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
  src: local('Montserrat-Medium'), url(./assets/fonts/Montserrat-Bold.ttf) format('truetype');
}